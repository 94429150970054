import {
  Product,
  ProductDraft,
  ProductVariant,
  ProductVariantDraft,
} from "@commercetools/platform-sdk";
import { DEFAULT_LOCALE } from "../constants";

const generateSlug = (key: string) =>
  key
    .replace(/\s/g, "-")
    .replace(/[^a-zA-Z\d-]/g, "_")
    .toLowerCase();

const replaceKey = (field: string, oldKey: string, newKey: string) =>
  field.replace(new RegExp(oldKey, "ig"), newKey);

const mapDuplicateProductDraft = (
  product: Product,
  key: string,
  name: string
): ProductDraft => {
  const stagedData = product.masterData.staged;

  const convertVariantToDraft = (
    variant: ProductVariant
  ): ProductVariantDraft => {
    return {
      attributes: variant.attributes?.filter(
        (attr) => attr.name !== "overwrite-prices"
      ),
      assets: variant.assets,
      key: variant.key
        ? replaceKey(variant.key as string, product.key as string, key)
        : undefined,
      images: [],
      sku: replaceKey(variant.sku as string, product.key as string, key),
      prices: variant.prices?.map((price) => {
        const { discounted, id, custom, ...priceDraft } = price;
        return priceDraft;
      }),
    };
  };

  return {
    ...stagedData,
    productType: product.productType,
    key,
    name: {
      [DEFAULT_LOCALE]: name,
    },
    slug: {
      [DEFAULT_LOCALE]: generateSlug(key),
    },
    masterVariant: convertVariantToDraft(stagedData.masterVariant),
    variants: stagedData.variants?.map(convertVariantToDraft),
    taxCategory: product.taxCategory,
  };
};
export { mapDuplicateProductDraft };
